@import "bootstrap_3_3_6/bootstrap/_variables";

.hovereffect-hero {
    width: 100%;
    height: 100%;
    float: left;
    position: relative;
    cursor: default;
    margin-bottom: 15px;
    border: 1px solid $gray-light;

    @media(min-width: 992px){
        height: 670px;
    }
    @media(min-width: 1200px){
        height: 700px;
    }
}

.hovereffect-hero .anchor-wrapper{
    display: block;
    width: 100%;
    height: 100%;
}

.hovereffect-hero .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    background-color: rgba(0,0,0,0.5);
}

.hovereffect-hero img.hovereffect-primary-image {
    display: block;
    position: relative;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    max-width: 100%;

    @media(max-width: 650px){
        margin: 0 auto;
    }

    @media(min-width: 651px){
        margin: 15px;
    }
    @media(min-width: 992px){
        margin: 0 auto;
        max-height: 319px;
        width: 100%;
    }
    @media(min-width: 1200px){
        max-height: 394px;
    }
}

.hovereffect-hero h3 {
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    margin: 0px;
    padding: 15px;
    color:#fff;

    @media(max-width: 767px){
        text-align: center;
    }
    @media(min-width: 768px){
        font-size: 1.2em;
        width: 100%;
    }
    @media(min-width: 992px){
        font-size: 1.6em;
    }
    @media(min-width: 1200px){
        font-size: 1.8em;
    }
}

.hovereffect-hero h3.primary{
    background-color: $brand-primary;
}

.hovereffect-hero h3.warning{
    background-color: $brand-warning;
}

.hovereffect-hero h3.danger{
    background-color: $brand-danger;
}

.hovereffect-hero h3.info{
    background-color: $brand-info;
}

.hovereffect-hero h3.default{
    background-color: #fff;
    color: $brand-primary;
}

.hovereffect-hero span.info {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    border: 1px solid #fff;
    background-color: transparent;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    padding: 10px 15px;
    position: absolute;
    bottom: 50%;
    letter-spacing: 0.3em;
    background-color: rgba(0,0,0, 0.4);

    @media(max-width: 250px){
        left: 20%;
    }
    @media(min-width: 251px){
        left: 20%;
    }
    @media(min-width: 301px){
        left: 25%;
    }
    @media(min-width: 351px){
        left: 30%;
    }
    @media(min-width: 401px){
        left: 35%;
    }
    @media(min-width: 451px){
        left: 40% !important;
    }
    @media(min-width: 768px){
        left: 42% !important;
    }
    @media(min-width: 992px){
        left: 35% !important;
    }
    @media(min-width: 1200px){
        left: 40% !important;
    }
}

.hovereffect-hero span.info:hover {
    box-shadow: 0 0 5px #fff;
}

.hovereffect-hero:hover .overlay {
    opacity: 1;
    filter: alpha(opacity=100);
}

.hovereffect-hero:hover h3,.hovereffect-hero:hover span.info {
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-transform: translatey(0);
    -webkit-transform: translatey(0);
    transform: translatey(0);
}

.hovereffect-hero:hover span.info {
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
}

.hovereffect-hero .hovereffect-secondary-image{
    display: block;
    margin: 15px;
}

.hovereffect-hero p{
    padding: 0px 15px 15px 15px;
    color: $gray-base;
}